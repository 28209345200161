import { combineReducers } from 'redux'
import customizer from './customizer'
import auth from './auth'
import users from './users'
import products from './products'
import surveys from './surveys'
import rewards from './rewards'
import regions from './regions'
import offers from './offers'
import locations from './locations'
import drawings from './drawings'
import configurations from './configurations'
import beacons from './beacons'
import notifications from './notifications'
import promotions from './promotions'
import promotionWinners from './promotionWinners'
import tags from './tags'
import machineManufacturers from './machineManufacturers'
import machineModels from './machineModels'
import gamesets from './gamesets'
import games from './games'
import employees from './employees'
import roles from './roles'
import checkins from './checkins'
import marketing from './marketing'
import regionGroups from './regionGroups'
import prizes from './prizes'

const rootReducer = combineReducers({
  customizer,
  auth,
  users,
  surveys,
  rewards,
  regions,
  offers,
  locations,
  drawings,
  products,
  configurations,
  beacons,
  notifications,
  promotions,
  promotionWinners,
  tags,
  gamesets,
  machineManufacturers,
  machineModels,
  games,
  employees,
  roles,
  checkins,
  marketing,
  regionGroups,
  prizes
})

export default rootReducer
